<template>
    <mercur-card v-if="isAllowedTo('SupplierCentral/getArtworkFiles')" class="fill full-height-layout mx-4 mb-3">
        <grid-header :quickSearch.sync="filters.search">
            Artwork files
        </grid-header>

        <data-table
            class="fill full-height-layout border"
            :options="options"
            :url="url"
            :quickSearch="filters.search"
            v-if="isAllowedTo('SupplierCentral/getArtworkFiles')"
        ></data-table>
    </mercur-card>
    <div v-else>
        <p class="permission-message">Not allowed to see this view</p>
    </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import CONFIG from '@root/config'
import GridHeader from '@/components/GridHeader'

export default {
    name: 'ArtworkFilesOverview',
    components: { DataTable, GridHeader },
    data () {
        return {
            options: {
                columns: {
                    'Filename': {
                        field: 'artworkConfigurationFileName',
                        sortable: true,
                    },
                    'Type': {
                        field: 'artworkConfigurationFileType',
                        sortable: true,
                    },
                    'Updated': {
                        field: 'dateUpdated',
                        sortable: true,
                    },
                },

                quickSearchColumns: ['artworkConfigurationFileName', 'artworkConfigurationFileType'],
            },
            url: CONFIG.API.ROUTES.ARTWORK.FILES.OVERVIEW,
            filters: {
                search: '',
            },
        }
    },
}
</script>
